.basic-page {
  display       : flex;
  flex-direction: column;
  width         : 100%;

  .example__container {
    display       : flex;
    flex-direction: column;
    margin-bottom : $page-padding;

    &>header {
      display      : flex;
      margin-bottom: $content-padding;
    }

    .button-list {
      button {
        margin-right: $content-padding;
      }
    }
  }

  .content-header__container {
    display        : flex;
    justify-content: space-between;
    margin-bottom  : $page-padding;
    align-items    : center;
    background     : #fff;
    padding        : $content-padding;
    box-sizing     : border-box;
    border-radius  : $basic-border-radius;
    box-shadow     : $box-shadow;
  }

  .form__container {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    background    : #fff;
    padding       : $content-padding;
    box-sizing    : border-box;
    border-radius : $basic-border-radius;
    box-shadow    : $box-shadow;

    &>header {
      display      : flex;
      margin-bottom: $content-padding;
    }

    .flex {
      display       : flex;
      flex-direction: column;

      &>article {
        margin-bottom: $content-padding;
      }
    }
  }

  .filter__container {
    display       : flex;
    flex-direction: column;
    margin-bottom : $page-padding;
  }

  .table__container {
    display       : flex;
    flex-direction: column;
    margin-bottom : $page-padding;
  }

  .paging__container {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    margin-bottom : $page-padding;
  }

  .membership__container {
    display       : flex;
    flex-direction: column;

    ul {
      display       : flex;
      flex-direction: column;

      li {
        display: flex;

      }
    }
  }
}

.subscription__container {
  display       : flex;
  flex-direction: column;

  .subscription__item {
    display      : flex;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0px;
    }

    .form-text-viewer {
      margin-right: 24px;

      &>header {
        min-width: 140px;
      }

      &:first-child {
        width: 600px;
      }

      &:nth-child(2) {
        flex        : 1;
        margin-right: 0px;

        &>header {
          min-width: 80px;
        }
      }
    }

    button {
      width: 160px;
    }
  }
}